.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.heading {
  text-align: center !important;
  margin-inline: auto;
  font-weight: var(--font-weight-midBold);
  font-size: var(--font-4xl);
}

.subHeading,
.description {
  font-size: var(--font-sm);
  line-height: 1.5rem;
}

.cta {
  padding: 10px 18px;
  text-align: center;
  width: fit-content;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  border: none;
  cursor: pointer;
  color: var(--black);
  transition: all 0.5s ease;
  background-color: var(--yellow);

  display: flex;
  align-items: center;
  gap: 4px;
}

.cta:hover {
  box-shadow: var(--section-card-box-shadow);
}

.flexgrid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.flexgrid > * {
  flex: 1 1 calc(33.33% - 16px);
  max-width: calc(33.33% - 16px);
  box-sizing: border-box;
}

.btnsArea {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.loadMoreBtn {
  background: var(--black);
  color: white;
  border: none;
  padding: 10px 18px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  cursor: pointer;
}

@media (max-width: 768px) {
  .flexgrid > * {
    flex: 1 1 calc(50% - 16px);
    max-width: calc(50% - 16px);
  }
}

@media (max-width: 480px) {
  .flexgrid > * {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
